;(function() {
  /*  DOM Variable Declaration */
  let ch;
  let $hamburgerWrap;
  let $menu;
  let $pageHeader;
  let $subWrap;  
  let pageHeaderH;
  let $playlist;

  if (isExist('.videos-page')) {
    $playlist = $('.playlistWrap > .playlist');
  }

  /* Constant Variable */
  const ACTIVE_CLASS_NAME = 'active';
  const AUTOPLAY_SPEED = 5000;
  const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const chName = {
    animal: 'ap',
    asia: 'asia',
    dmax: 'dmax',
    science: 'sci'
  }
  
  const THIS_CHANNEL = window.THIS_CHANNEL || ''; /* THIS_CHANNEL 定義於每隻檔案的<header> */

  /* Cookie Right */
  (function() {
    let cookieRight = document.cookie.replace(/(?:(?:^|.*;\s*)agree_cookie_right\s*\=\s*([^;]*).*$)|^.*$/, "$1");;
    

    if (!$('#privacy').length && !cookieRight) {
      $('body').append(`
        <div class="cookieRight">
          <div class="container">
            <div>
              <p>本網站使用Cookies。<br class="mobile"/>繼續瀏覽該網站即表示您同意我們使用Cookie。<br class="mobile"/><a href="./privacy.php">了解更多</a></p>
              <div class="closeBtn"></div>
            </div>
          </div>
        </div>
      `);
  
      $('.cookieRight .closeBtn').on('click', () => {
        document.cookie = "agree_cookie_right = true";

        $('.cookieRight').fadeOut(DURATION.STD, function() {
          $(this).remove();
        });
      });
    }
  })();



  /* Header/Footer Loading */
  includesLoader({
    includesList: [
      /***** Page Header *****/
      {
        target: '.page-header',
        fileName: '_page-header',
        callback: () => {
          /* Variable Assignment */
          $hamburgerWrap = $('.hamburgerWrap');
          $menu = $('.menu');
          $pageHeader = $('.page-header');
          
          /* Initialization Setting */
          // Sub LOGO Setting
          ch = $('body').attr('id');
          if (!!chName[ch]) {
            const chLogoTemp = `
              <a class="chLogo" href="./${chName[ch]}_index.php">
                <img src="./images/logo/${chName[ch]}_logo@3x.png" alt="${ch}"/>
              </a>
            `
            $('.page-header .headerLeft').append(chLogoTemp);

            let fbLink;
            switch (chName[ch]) {
              case 'ap':
                fbLink = 'https://www.facebook.com/AnimalPlanetTaiwan/';
                break;
            }

            $('.page-header .fbBtn > a').attr('href', fbLink);
          }
          if( $('body').hasClass('d-garage-page') ){
            console.log('到底~~~');
            
            const chLogoTemp = `
              <a class="chLogo" href="./d-garage.php">
                <img src="./images/logo/motortrend_logo@3x.png" alt="motortrend"/>
              </a>
            `
            
            $('.page-header .headerLeft').append(chLogoTemp);
          };
          
		  
		  if(THIS_CHANNEL == 'animal') $('.headerVideosLink').attr('href','ap_videos.php');
          

          /* Events */
          // Header : Hamburger Click Event
          $hamburgerWrap.on('click', evt => {
            evt.stopPropagation();
            !menuSysStateCheck() ? openMenu() : closeMenu();
          });
          $menu.on('click', evt => {
            evt.stopPropagation();
          });
          $docbody.on('click', () => {
            menuSysStateCheck() && closeMenu();
          });
          // HamburgerWrap & Menu 狀態驗證 (是否有活躍的 Class Name)
          const menuSysStateCheck = () => $hamburgerWrap.hasClass(ACTIVE_CLASS_NAME) || $menu.hasClass(ACTIVE_CLASS_NAME);

          /* Library */
          function openMenu() {
            $hamburgerWrap.addClass(ACTIVE_CLASS_NAME);
            $menu.addClass(ACTIVE_CLASS_NAME);
          }
          function closeMenu() {
            $hamburgerWrap.removeClass(ACTIVE_CLASS_NAME);
            $menu.removeClass(ACTIVE_CLASS_NAME);
          }
        }
      },
      /***** Page Footer *****/
      {
        target: '.page-footer',
        fileName: '_page-footer',
        callback: () => {
		  $subWrap = $('#subWrap');	
          // Footer: Slick
          $('._slickOtherChannel').slick({
            dots: false,
            arrows: true,
            infinite: false,
            speed: DURATION.NOR,
            slidesToShow: 3,
            slidesToScroll: 3,
            mobileFirst: true,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 6,
                }
              },
              {
                breakpoint: 1366,
                settings: {
                  slidesToShow: 8,
                  slidesToScroll: 8,
                }
              },
            ]
          });
        
          /* Events */
          // Footer: Subcribe ePaper
          $subWrap.find('.subBtn').on('click',function(){
            var email = $subWrap.find('[type=email]').val();	  
            var $chk = $subWrap.find('[type=checkbox]');
            var msg = '';
            if(email == '' || !email.match(EMAIL_REG)) msg = '請輸入正確EMAIL格式';
            else if(!$chk.is(':checked')) msg = '請勾選"我同意Discovery Communications隱私政策"';
            if(msg){
            alert(msg);
            return;
            }
            $.ajax({
            url:'ajax/subscribeadd.php', type:'POST', dataType:'json', data:{email:email},
              success:function(res){
              if(res.success) alert('訂閱完成');
            }
            });
          });	
        
          // Footer: Go Top Button
          $('.goTop').on('click', function() {
            $docbody.animate({
              scrollTop: 0
            }, DURATION.STD);
          });
        }
      },
    ],
    callback: componentLoader
  })

  // Component Loading
  function componentLoader() {
    includesLoader({
      includesList: [
        /***** Youtube Subscribe Button *****/
        {
          target: '.ytBtn',
          fileName: '_yt-btn',
          callback: () => {
			  //var THIS_CHANNEL = window.THIS_CHANNEL || ''; /* THIS_CHANNEL 定義於每隻檔案的<header> */
			  var channelid;
			  switch(THIS_CHANNEL){ 
				  case 'discovery': channelid = 'UCsNBl_rgThimjxCyU0MAaPQ'; break;
				  case 'animal': channelid = 'UCypzlOdJyyOR2NhKv0o3zig'; break;
				  case 'asia': channelid = 'UCZ6I2Buum30TpLQTB_vEm2g'; break;
				  case 'science': channelid = 'UCsNBl_rgThimjxCyU0MAaPQ'; break;
				  case 'dmax': channelid = 'UCuEgo07zsZr_FTcNTETRS5Q'; break;
				  default: channelid = 'UCsNBl_rgThimjxCyU0MAaPQ';
			  }
			  $('.ytBtn>a').attr('href', 'https://www.youtube.com/channel/'+channelid+'?sub_confirmation=1');
		  }
        }
      ],
      callback: mainFunc
    })
  }

  
  

  /*==================================================*\
          Main Function
  \*==================================================*/
  function mainFunc() {
    /* window 事件: Resize & scroll */
    windowEvents();

    if(winW > 1024){

      //default no hover
      var realHover = 0;
  
      //有submenu的選單滑入
      $(".headerRight .hasSubNav").hover(function () {
        realHover = 1;
        clearTimeout(outSubMenu);
        $('.subNav').css({opacity: 1, pointerEvents: 'auto', transform: 'translateY(100%)'});
      }, function () {
        realHover = 0;
        setTimeout(outSubMenu, 300);
      });
  
      $(".subNav").hover(function () {
        realHover = 1;
        clearTimeout(outSubMenu);
        $(this).css({opacity: 1, pointerEvents: 'auto', transform: 'translateY(100%)'});
      });
  
      function outSubMenu(){
        if(realHover == 1){
          return false;
        }else if (realHover == 0){
          setTimeout(function(){
            realHover = 0;
            $('.subNav').css({opacity: 0, pointerEvents: 'none', transform: 'translateY(0)'});
          }, 300)
        }
      }
  
    }

    /***** for avoid "Checkbox Jump Up" Bug *****/
    $('.checkboxWrap').on('click', function(evt) {
      evt.preventDefault();
      var $checkbox = $(this).find('input');
      $checkbox.prop('checked', !$checkbox.prop('checked'));
    });
    if (isExist('select')) {
      selectPlaceholder();
      $('select').on('change', selectPlaceholder);

      const SELECT_ACTIVE_NAME = 'hasVal';
      function selectPlaceholder() {
        var $this = $(this);

        if ($this.val() != '') {
          $this.addClass(SELECT_ACTIVE_NAME)
        } else {
          $this.removeClass(SELECT_ACTIVE_NAME)
        }
      }
    }

    // Hero Carousel
    if (!!$('._heroSlick').length) {
      $('._heroSlick').slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: AUTOPLAY_SPEED,
      });
    }

    // Lightbox: 下載節目表
    if (isExist('.downloadBtn')) {
      $('.downloadBtn').lightbox();
    }

    /***** Index Page Setting *****/
    if (isExist('.index-page')) {
      // $('.scrollNextWrap').on('click', () => {
      //   $docbody.animate({
      //     scrollTop: $('.showList').offset().top - pageHeaderH
      //   }, DURATION.NOR);
      // });

      if (isExist('.programItemWrap')) {
        $('.programItemWrap').mCustomScrollbar({
          scrollInertia: 0,
          // setTop: $('.programItemWrap .active').parent().position().top * -1
        });
      }

      // Show List Carousel
      $('._showListSlick').slick({
        arrows: true,
        dots: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              variableWidth: true,
            }
          }
        ]
      });

      // HOT 
      $('._hotSlick').slick({
        arrows: false,
        dots: true,
      });

      // Hot Show for Mobile Only
      $('.hot .item .summary').on('click', function() {
        $(this).parents('.item').toggleClass('active');
      });

      /* 將節目表scroll到現在時間的節目 */
	  if($('.programItemWrap').length) $('.programItemWrap').mCustomScrollbar('scrollTo','#activePgItem',{scrollInertia:0});
    }

    /***** Program Page Setting *****/
    // if (isExist('.program-page') || isExist('.program_result-page')) {
      // Lightbox
      // $('.downloadBtn').lightbox();
      // Program List
	  /*
      $('.listItem header').on('click', function() {
        const $this = $(this);
        $this.siblings('article').stop().slideToggle(DURATION.STD);
        $this.toggleClass('isOpen');
      });
	  */
    // }
    if (isExist('.program-page')) {
      /* Variables */
      var $dateBtn = $('.dateBtn');

      // 日期設定
      const todayStr = `${(today.getMonth() + 1)}/${today.getDate()}`;
      const tomorrowStr = `${(tomorrow.getMonth() + 1)}/${tomorrow.getDate()}`;
      const todayTarget =  $dateBtn.filter((idx, el) => $(el).children('p').eq(0).text() === todayStr);
      const tomorrowTarget =  $dateBtn.filter((idx, el) => $(el).children('p').eq(0).text() === tomorrowStr);
      todayTarget.addClass(ACTIVE_CLASS_NAME);
      todayTarget.children('p').eq(0).html(`<strong>今天</strong> ${todayStr}`);
      tomorrowTarget.children('p').eq(0).html(`<strong>明天</strong> ${tomorrowStr}`);

	  /*
      $('._dateSlick').slick({
        infinite: false,
        arrows: true,
        dots: false,		
		initialSlide: 5,
        slidesToShow: 4,
        slidesToScroll: 4,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          },
        ]
      });
      */
	  
	  $('._dateSlick').slick({
       infinite: false,
       arrows: true,
       dots: false,
        //focusOnSelect: true,
        initialSlide: 10,
       slidesToShow: 5,
       responsive: [
         {
           breakpoint: 1023,
           settings: {
             slidesToShow: 4,
           }
         },
       ]
     });
	  
	  /*
      $dateBtn.on('click', function(evt) {
        evt.preventDefault();
        $dateBtn.removeClass(ACTIVE_CLASS_NAME);
        $(this).addClass(ACTIVE_CLASS_NAME);
      });
	  */
    }



    /***** Videos Page Setting *****/
    if (isExist('.videos-page')) {
      /* Initialization Setting */
      setVideosSubPlaylistArrowPos();
      
      /* Events */
      $('.subPlaylist header > p').on('click', function() {
        $(this).toggleClass('active');
      });
    }



    /***** News-Page Page Setting *****/
    if (isExist('._newsPageSlick')) {
      const $newsPageSlick = $('._newsPageSlick');

      $newsPageSlick.on('init', function() {
        let max;
        let allH = [];
        const allItem = $(this).find('.item');
        $.each(allItem, (idx, el) => {
          allH.push($(el).height());
        });

        max = Math.max(...allH);
        allItem.height(max);
      });

      $newsPageSlick.slick({
        infinite: false,
        arrows: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          }
        ]
      }).slick('slickGoTo', $('._newsPageSlick .active').data('slick-index'));
    }




    /* 收掉 .page-loading */
    transitionThenRemove({
      dom: $('.page-loading'),
      duration: DURATION.STD,
      callback: () => {
        console.log('%cBuild Completed!', logSafeStyle)
      }
    })
	
	var fbClick = function(e){
		e.preventDefault();
		window.open('https://www.facebook.com/sharer/sharer.php?u='+location.href);
	}
	var lineClick = function(e){
		e.preventDefault();
		window.open('https://timeline.line.me/social-plugin/share?url='+location.href);
	}
	var copyLink = function(e){
		e.preventDefault();
		var dummy = document.createElement('input');
		document.body.appendChild(dummy);		
		dummy.value = location.href;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
		alert('網址已複製');
	}
	if(isExist('#shareBarFb')) $('#shareBarFb').on('click',fbClick);
	if(isExist('#shareBarLine')) $('#shareBarLine').on('click',lineClick);
	if(isExist('#shareBarLink')) $('#shareBarLink').on('click',copyLink);
  }


 

  /*==================================================*\
          window Events
  \*==================================================*/
  let isFirstTime = true
  function windowEvents() {
    // Window Resize
    $win.on('resize', _resize).resize()
    // Window Scroll
    $win.on('scroll', _scroll).scroll()
  }

  // /*========== Window Resize ==========*/
  var mqOptions = [
    {
      minimum: -1,
      mq: function() {}
    },
    {
      phoneWide: 480,
      mq: function() {}
    },
    {
      tabletSmall: 640,
      mq: function() {}
    },
    {
      tabletWide: 1024,
      mq: function() {}
    },
    {
      notebook: 1366,
      mq: function() {}
    },
  ]

  function _resize() {
    getSize()
    setProgramScrollbar()

    // 斷點偵測
    detectiveBreakpoint(mqOptions)

    /***** Videos Page Setting *****/
    if (isExist('.videos-page')) {
      // 設定 Main Playlist 高度
      function setMainPlaylistHeight() {
        const $mainPlaylistItem = $('.playlistWrap li');
        const numberOfShowItem = 3;
        let limitH = 0;
        for (var i=0; i<numberOfShowItem; i++) {
          limitH += ($mainPlaylistItem.eq(i).outerHeight());
        }
        return limitH;        
      }

      if (winW <= 1024) {
        $playlist.height(setMainPlaylistHeight());
        if (isExist('.subPlaylist .slick-slider')) {
          $('.subPlaylist ul').slick("unslick");
        }
      } else {
        setVideosSubPlaylistArrowPos();

        $playlist.outerHeight($('.mainVideo').height());
        if (!isExist('.subPlaylist .slick-slider')) {
          $('.subPlaylist ul').slick({
            infinite: false,
            arrows: true,
            dots: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 4
          });
        }
      }

      if (isExist('.mCustomScrollbar')) {
        $playlist.mCustomScrollbar("destroy");
      }

      $playlist.mCustomScrollbar({
        scrollInertia: 300,
      });
    }
  }

  /* 取得寬高 */
  function getSize() {
    winW = $win.outerWidth()
    winH = $win.outerHeight()
    pageHeaderH = $pageHeader.height();
  }

  // Set Program Scrollbar
  function setProgramScrollbar() {
	/* 將節目表scroll到現在時間的節目 */
    //if($('.programItemWrap').length && $('#activePgItem').length) $('.programItemWrap').scrollTop($('#activePgItem').offset().top - $('.programItemWrap').offset().top);    
	
    if(!isExist('.program-page')) {
      return false;
    }

    if (
      !!$('.programItemWrap').length
      &&
      !$('.mCustomScrollbar').length
      &&
      winW >= 1024
    ) {
      $('.programItemWrap').mCustomScrollbar({
        scrollInertia: 0
      });
    } else if (
      !!$('.programItemWrap').length
      &&
      !!$('.mCustomScrollbar').length
      &&
      winW < 1024
    ) {
      $('.programItemWrap').mCustomScrollbar("destroy");	  
    }
    else {
      /* 將節目表scroll到現在時間的節目 */
      //if($('.programItemWrap').length) $('.programItemWrap').scrollTop($('#activePgItem').offset().top - $('.programItemWrap').offset().top);
    }
  }

  // Set Videos SubPlaylist Arrows Position
  function setVideosSubPlaylistArrowPos() {
    if (!isExist('.subPlaylistWrap .slick-arrow')) { return false; }
    
    $('.subPlaylistWrap .slick-arrow').css({
      top: ($('.subPlaylist .imgWrap img').height() / 2 - 25)
    });
  }

  // /*========== Window Scroll ==========*/
  let nowPos
  function _scroll() {
    nowPos = getPos()
  }

  /* 取得位置 */
  function getPos() {
    return {
      x: $doc.scrollLeft(),
      y: $doc.scrollTop()
    }
  }

  /*==================================================*\
          Library
  \*==================================================*/
  /* 斷點偵測 (for window resize) */
  let nowBP;
  let preBP = { minimum: -1 }
  function detectiveBreakpoint(breakpoint) {
    nowBP = breakpoint[0]

    $.each(breakpoint, (idx, obj) => {
      const objName = Object.getOwnPropertyNames(obj)[0]
      const val = obj[objName]

      if (winW > val && val > nowBP[Object.getOwnPropertyNames(nowBP)[0]]) {
        nowBP = obj
      }
    })

    if (
      Object.getOwnPropertyNames(nowBP)[0] ===
      Object.getOwnPropertyNames(preBP)[0]
    ) {
      return false
    }

    // 執行 Media Query
    mediaQuery(nowBP)
  }

  /* 執行 Media Query */
  function mediaQuery(nowBP) {
    const nowBPName = Object.getOwnPropertyNames(nowBP)[0]
    console.log(`Breakpoint {${nowBPName}: ${nowBP[nowBPName]}}`)

    // 執行該斷點 Media Query
    if (!nowBP.hasOwnProperty('mq')) {
      throw new Error(`此斷點(↑)尚未設定 Media Query 之屬性 "mq"(function)`)
    } else if (typeof nowBP.mq !== 'function') {
      throw new Error(`此中斷點之 Media Query 型別並非 "function`)
    } else {
      nowBP.mq()
    }
    preBP = nowBP
  }
})();
